import React from 'react'
import { graphql } from 'gatsby'
import { get, find } from 'lodash-es'

import Page from '../../components/page'
import PortfolioSection from '../../sections/PortfolioSection/PortfolioSection'

const ProjectCategoryTemplate = (props) => {
    const { title = '', globals, handle } = props.pageContext
    const siteTitle = get(props, 'data.site.siteMetadata.title')
    const homepage = get(props, 'data.contentfulPage')
    const projects = get(props, 'data.projects.edges', []).map((edge) => edge.node)

    const portfolioTitle = get(
        find(
            get(homepage, 'sections', []),
            (section) => section.type == 'ContentfulSectionProjects'
        ),
        'title',
        'We build brands to speak to humans, not strategy.'
    )

    return (
        <Page
            id={`project-category-${handle}`}
            title={`${title} Work`}
            siteTitle={siteTitle}
            location={props.location}
            navbarRetainer
        >
            <PortfolioSection
                title={portfolioTitle}
                globals={globals}
                projects={projects}
                currentCategory={handle}
            />
        </Page>
    )
}

export default ProjectCategoryTemplate

export const pageQuery = graphql`
    query ProjectCategoryByHandle($handle: String!) {
        site {
            siteMetadata {
                title
            }
        }

        contentfulPage(handle: { eq: "homepage" }) {
            ...pageFragment
        }

        projects: allContentfulProject(
            filter: { categories: { elemMatch: { handle: { eq: $handle } } } }
        ) {
            edges {
                node {
                    coverImage {
                        fluid(maxWidth: 2560) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }

                    title
                    handle

                    categories {
                        title
                    }
                }
            }
        }
    }
`
